<template>
  <div class="basic-card-block margin-top-0">
    <div class="flex-between-row"  style="display: flex;  flex-direction:row; width: 400px;">
      <div class="flex-between-column">
        <div class="sub-title-text" style=" text-align: center; font-size: 16px;"> XXXXXX1  Top</div>
        <div class="sub-title-text" style=" text-align: center; font-weight: 400;">XXXXXX1  bottom </div>
      </div>
      <div class="flex-between-column">
        <div class="sub-title-text" style="text-align: center; font-size: 16px;"> XXXXXX2  Top</div>
        <div class="sub-title-text" style="text-align: center; font-weight: 400;">XXXXXX2  bottom </div>
      </div>

    </div>
    <h1>Summary Form ????</h1>
    <div>
      <div class="div-frac-13-avg">
        <el-form-item label="Company Name"   :rules="$props.rules.reqRule">
          <el-select v-model="orderForm.buyerSysOrganizationId" filterable value-key="id"
            @change="onChangeCompanyName($event)"
            :disabled="$attrs.propsView === ORDER_VIEW.VIEW_ORDER || $attrs.propsView === ORDER_VIEW.EDIT_ORDER || $props.linkedSalesTermContract !== null"
          >
            <el-option
              v-for="item in creditAssociationList"
              :key="item.id" :label="item.counterpartyCompanyName" :value="item.counterpartySysOrganizationId"
              :disabled="item.status !== 'ACTIVE'"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Territory"   :rules="$props.rules.reqRule">
          <el-select v-model="orderForm.buyerSysOrganizationId" filterable value-key="id"
            @change="onChangeCompanyName($event)"
            :disabled="$attrs.propsView === ORDER_VIEW.VIEW_ORDER || $attrs.propsView === ORDER_VIEW.EDIT_ORDER || !!$props.linkedSalesTermContract"
          >
            <el-option
              v-for="item in creditAssociationList"
              :key="item.id" :label="item.counterpartyCompanyName" :value="item.counterpartySysOrganizationId"
              :disabled="item.status !== 'ACTIVE'"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Order Date" :rules="rules.reqRule">
          <el-date-picker
            type="date" format="dd-MM-yyyy" :disabled="$attrs.propsView === ORDER_VIEW.VIEW_ORDER"
            v-model="orderForm.orderDate"
            @change="orderForm.nominationDate = null"
          />
        </el-form-item>
        <el-form-item label="Search Vessel" v-if="!isVesselTBC" >
          <el-select
            :filter-method="(val)=>$emit('handleSearchMpaReceivingVessels',val)"
            filterable
            popper-class="custom-ship-selector"
            v-model="vesselSearchKey"
            style="width:100%;"
            :disabled="$attrs.propsView === ORDER_VIEW.VIEW_ORDER"
            @visible-change="$emit('searchVesselvisibleChange',$event)"
            @change="$emit('handleSelectVessel',$event)"
            placeholder="Select">
            <span style="display: inline-block; width: 200px; font-size: 14px; font-weight: 500; margin-left: 20px; margin-bottom: 10px;">Name</span>
            <span style="display: inline-block; width: 100px; font-size: 14px; font-weight: 500;">IMO</span>
            <span style="display: inline-block; font-size: 14px; font-weight: 500;">Licence</span>
            <el-option
              v-for="item in searchedVesselList||[]"
              :key="item.id"
              :label="item.shipName"
              :value="item.id">
                <span style="display: inline-block; width: 200px; font-size: 12px;">{{item.shipName}} </span>
                <span style="display: inline-block; width: 100px; font-size: 12px;">{{item.imo}}</span>
                <span style="display: inline-block; font-size: 12px;">{{item.licenceNo}}</span>
            </el-option>
          </el-select>
      </el-form-item>
      </div>
    </div>
  </div>
</template>

<script>
import { ORDER_VIEW } from '@/constants.js'
import { mapState } from 'vuex'
export default {
  name: 'TopSummaryForm',
  props: {
    rules: Object,
    orderForm: Object,
    linkedSalesTermContract:Object,
    isVesselTBC:Boolean,
    searchedVesselList: Array,
    vesselSearchKey: String,
  },
  data () {

    return {
      ORDER_VIEW,

    }
  },
  computed: {
    ...mapState([
      'currencyList',
      'currentCompanyUserList',
      'creditAssociationList',
      'locationList',
      'productList',
      'productSpecificationList',
      'vesselList'
    ]),
  },
  watch: {

  },
  created () {

  },
  methods: {
    onChangeCompanyName($event){
      this.$emit('handleCompanyNameSelect',$event, 'BUYER')
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../index";
</style>
